:root{
  --main-gold: rgba(255, 184, 0, 1);
  --semi-transparent-gold: rgba(255, 184, 0, 0.7);
  --transparent-gold: rgba(255, 183, 0, 0.1);
  --darker-gold: rgb(163, 117, 0);
  --faded-gold: rgba(148, 141, 104, 1);
  --faded-gold-lighter: rgba(170, 162, 120, 0.4);
  --transparent-faded-gold: rgba(148, 141, 104, 0.4);
  --light-gold: rgb(250, 213, 90);
  --lighter-gold: rgb(252, 226, 142);
  --h1-text-faint-gold: rgb(255, 242, 205);
  --text-gold: rgba(205, 199, 167, 1);
  --text-gold-whiter: rgb(230, 223, 188);
  --text-gold-lighter: rgb(212, 185, 116);
  --drop-shadow-gold: rgba(255, 199, 0, 0.25);
  --main-white: whitesmoke;
  --faded-white: rgba(193, 193, 193, 1);
  --main-grey: rgba(43, 43, 43, 1);
  --dark-grey: rgba(37, 37, 37, 1);
  --lighter-grey: rgba(55, 55, 55, 1);
  --text-gray-darker: rgb(158, 158, 158);
  --text-gray: rgb(216, 216, 216);
  --text-gold-sidebar: rgb(209, 198, 139);
  --main-black: rgba(27, 27, 27, 1);
  --transparent-black: rgba(27, 27, 27, 0.5);
  --table-head-grey: rgba(43, 43, 43, 1);
  --light-green: rgb(164, 255, 164);
  --transparent-green: rgba(164, 255, 164, 0.1);
  --light-amber: rgb(253, 212, 101);
  --transparent-amber: rgba(253, 212, 101, 0.2);
  --light-red: rgb(252, 84, 84);
  --transparent-red: rgba(252, 84, 84, 0.3);
  --sidebar-focus-gold: rgb(41, 41, 33);
  --side-and-navbar-bg-color: rgb(29, 28, 25);
  --navbar-border-color: rgb(148, 141, 104);
  --sidebar-border-color: rgba(148, 141, 104, 0.5);
  --bond-table-header-bg-color: rgb(65, 62, 54);
  --bond-table-header-border-color: rgb(199, 194, 170);
  --bond-buy-bg-color: rgb(29, 28, 25);
  --bond-buy-text-container-color: rgb(37, 37, 35);
  --bond-create-text-container-color: rgb(44, 44, 41);
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*header css*/

.navbar-bg{
  background-color: var(--side-and-navbar-bg-color);
  border-bottom: 1px solid var(--navbar-border-color);
}

.mobile-nav-round{
  border-radius: 0 0 10px 10px;
}

/*backgrounds*/

.global-bg{
  background: radial-gradient(circle at top, rgb(51, 50, 46) 10%, rgb(27, 27, 27) 90%);
}

.global-bg-new{
  background: linear-gradient(180deg, rgba(33,31,24,1) 0%, rgba(90,85,67,1) 75%, rgba(18,17,12,1) 100%);
}

/*landing page*/

.landing-button{
  background: linear-gradient(var(--main-black), var(--main-black)) padding-box,
  linear-gradient(90deg, rgba(255, 201, 67, 0.7) 0%, rgba(255, 182, 0, 0.7) 100%) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  color: var(--text-gold);
}

/*end of landing page*/

/*side bar*/

.sidebar-open-button{
  top: 5.7rem;
  left: 1rem;
  position: fixed;
  z-index: 20;
}

.sidebar-bg{
  background-color: var(--side-and-navbar-bg-color);
  border-right: 0.1rem solid var(--sidebar-border-color);
  height: 100vh;
}

.button-sidebar{
  color: var(--text-gray);
  font-size: 1rem;
  font-weight: 500;
  padding-left: 1rem;
}

.button-sidebar:focus{
  color: var(--text-gold-sidebar);
}

.sidebar-item:focus-within{
  background-color: var(--sidebar-focus-gold);
}

.item-right{
  margin-left: auto;
}

/*end of side bar*/

/*create bond page*/

.align-qtty-and-price{
  align-items: end;
}

/*end of create bond page*/


/*seeker round page*/

/*connect button*/

.connect-button{
  background: linear-gradient(90deg, rgba(250,255,30,0.15) 0%, rgba(136,138,17,0.15) 100%);
  border: 1px solid var(--main-gold);
}

/*h1*/

.h1-color{
  color: var(--h1-text-faint-gold);
}

/*seeker div*/

.seeker-box{
  background-color: var(--main-black);
  box-shadow: 0 4px 60px -15px var(--drop-shadow-gold);
  max-width: 100%;
}

.input-div{
  background-color: var(--main-grey);
  border-color: var(--faded-gold);
  border-radius: 10px;
  max-width: 90%;
}

.input-usdc{
  max-width: 70%;
}

.usdc-bg-border{
  background-color: var(--lighter-grey);
  border-radius: 10px;
  margin: 0 10px 0 0;
}

.balance-div{
  background-color: var(--main-black);
  border-bottom: 1px solid var(--main-grey);
}

.balance-color{
  color: var(--faded-white) ;
}

.min-button{
  background: linear-gradient(var(--main-black), var(--main-black)) padding-box,
              linear-gradient(to right, rgba(255, 239, 199, 0.5) 0%, rgba(255, 218, 124, 0.5) 100%) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  margin-right: 5px;
  color: var(--text-gold);
}

.add-button{
  background: linear-gradient(var(--main-black), var(--main-black)) padding-box,
              linear-gradient(to right, rgba(255, 218, 124, 0.5) 0%, rgba(255, 201, 67, 0.5) 100%) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  margin-right: 5px;
  color: var(--text-gold);
}

.max-button{
  background: linear-gradient(var(--main-black), var(--main-black)) padding-box,
              linear-gradient(90deg, rgba(255, 201, 67, 0.5) 0%, rgba(255, 182, 0, 0.5) 100%) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  color: var(--text-gold);
}

.send-button{
  background: linear-gradient(135deg, rgba(255,184,0,0.5) 0%, rgba(255,217,119,0.5) 100%);
  border-radius: 10px;
  min-width: 80%;
  box-shadow: 0 5px 0 0 rgba(255, 235, 183, 0.5);
  transition: all 200ms ease-in-out;
}

.send-button-loading{
  background: linear-gradient(135deg, rgba(255,184,0,0.8) 0%, rgba(255,217,119,0.8) 100%);
}

.send-button:hover{
  transform: translateY(2px);
  box-shadow: 0 3px 0 0 rgba(255, 249, 235, 0.5);
  transition: all 200ms ease-in-out;
}

/*end of seeker round page*/

/*analytic dashboard page*/

.title{
  margin: 2rem 0.5rem 1.5rem;
  color: var(--h1-text-faint-gold);
}

.tot-treasury-wrapper{
  margin: 0 auto 2rem;
}

.tot-treasury-div{
  box-shadow: 0px 4px 45px 0px var(--drop-shadow-gold);
  /* background: linear-gradient(180deg, rgb(255, 208, 0) 0%, rgb(252, 217, 102) 100%); */
  background-color: var(--main-black);
  color: var(--main-white);
  transition: all 200ms ease-in-out;
}

.tot-treasury-div:hover{
  box-shadow: 0px 4px 65px 0px var(--drop-shadow-gold);
  transition: all 200ms ease-in-out;
}

.stats-divs{
  /* z-index: 99; */
  /* background: linear-gradient(0deg, rgb(255, 208, 0) 0%, rgb(252, 217, 102) 100%); */
  background-color: var(--main-black);
  color: var(--main-white);
}

.treasury-header{
  background: linear-gradient(90deg, rgb(255, 208, 0) 0%, rgb(255, 222, 112) 100%);
  padding: 0 1rem;
}

.treasury-content{
  background-color: var(--main-black);
  border-radius: 10px;
}

.asset-padding{
  padding: 1rem 0 1rem 1rem;
}

.underline{
  border-bottom: 1px solid var(--main-grey);
  padding: 1rem 0;
}

/*charts*/

.charts-bg{
  background-color: var(--main-black);
  border-radius: 10px;
}

/*pie chart*/

.pie-chart-container{
  height: 550px;
}

/*redeemable treasury*/

.redeemable-box{
  background-color: var(--main-black);
  box-shadow: 0 4px 60px -15px var(--drop-shadow-gold);
  max-width: 100%;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.input-bg{
  background-color: var(--main-grey);
  border: 1px solid var(--faded-gold);
  border-radius: 10px;
}

thead{
  background-color: var(--table-head-grey);
  /* border-radius: 10px; */
}

.table-header{
  color: var(--light-gold);
  border-radius: 10px;
}

.radius-left{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.radius-right{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-box{
  background-color: var(--table-black);
  border: 1px solid var(--lighter-grey);
  border-radius: 10px;
}

tbody .table-box:first-of-type{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

tbody .table-box:last-of-type{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-body{
  border: 1px solid var(--lighter-grey);
}

.redeem-assets{
  background-color: var(--dark-grey);
  border-radius: 10px;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.value-redeem, .value-redeemsm{
  color: var(--text-gold-lighter);
}

.redeem-val-flex, .value-redeemsm {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
}

.value-redeemsm{
  margin-top: 0.5rem;
}

.bg-color-s-fees {
  background-color: var(--transparent-green);
  color: var(--main-white);
  border: 1px solid var(--light-green);
  box-shadow: inset 0 0 2rem 0 var(--light-green);
  padding: 1.3rem;
}

.bg-color-m-fees {
  background-color: var(--transparent-amber);
  color: var(--main-white);
  border: 1px solid var(--light-amber);
  box-shadow: inset 0 0 2rem 0 var(--light-amber);
  padding: 1.3rem;
}

.bg-color-l-fees {
  background-color: var(--transparent-red);
  color: var(--main-white);
  border: 1px solid var(--light-red);
  box-shadow: inset 0 0 2rem 0 var(--light-red);
  padding: 1.3rem;
}

.redeem-button{
  background: linear-gradient(135deg, rgba(58, 52, 28, 1) 0%, rgb(212, 180, 62) 100%);
  box-shadow: 0 3px 0 0 rgba(255, 235, 183, 0.5);
  margin: 2rem 0 1.5rem;
  transition: all 300ms ease-in-out;
}

.redeem-button:hover{
  background: linear-gradient(135deg, rgb(68, 61, 33) 0%, rgb(212, 180, 62) 100%);
  transform: translateY(-2px);
  box-shadow: 0 5px 0 0 rgba(255, 235, 183, 0.5);
}

.rel-return{
  margin-right: 2rem;
  padding-left: 0.5rem;
}

/*restake page*/

.ophir-stats-container{
  width: 100%;
  background-color: var(--main-black);
  margin: 2rem 0 2rem 0;
}

.ophir-add-container {
  word-wrap: break-word;
  white-space: normal;
}

.global-vote-container {
  background-color: var(--main-black);
  box-shadow: 0 4px 60px -15px var(--drop-shadow-gold);
}

/* governance page*/

.govern-container {
  background-color: var(--main-black);
  box-shadow: 0 4px 60px -15px var(--drop-shadow-gold);
  min-width: 30vw;
  width: 80vw;
  height: fit-content;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 215, 0, 0.2);
}

.ophiramount-container {
  width: 100%;
  display: flex;
}

#ophirAmount {
  margin-top: 2.5rem;

}

.stake-button{
  background-color: var(--light-gold);
  box-shadow: 0 3px 0 0 rgba(255, 235, 183, 0.5);
  margin: 2rem 0 1.5rem;
  transition: all 300ms ease-in-out;
  width: 100%;
  min-width: 110px;
}

.stake-button:hover{
  background-color: var(--lighter-gold);
  transform: translateY(-1px);
  box-shadow: 0 5px 0 0 rgba(255, 235, 183, 0.5),
              0 5px 15px -5px var(--drop-shadow-gold);
}

.button-stake-tab-active{
  background-color: var(--light-gold);
}

.button-stake-tab-not-active{
  border: 1px solid var(--light-gold);
}

.staked-container{
  background-color: var(--dark-grey);
  border-radius: 0.75rem;
  padding: 1rem 0;
}

.staked-text{
  color: var(--text-gray-darker);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.available-text{
  color: var(--text-gray-darker);
  font-weight: 500;
}

.text-govern {
  color: #ffffff;
  margin-bottom: 1rem;
}

.h1-govern {
  text-align: center;
  color: #ffd700;
  font-weight: bold;
}

.h2-govern {
  color: #ffd700;
  font-weight: bold;
}

/* redeem analytics */

.main-container-margin-top {
  margin-top: 4rem;
}

.refresh-button {
  background: linear-gradient(135deg, rgba(58, 52, 28, 1) 0%, rgb(155, 132, 51) 100%);
  margin: 2rem 0 1.5rem;
  transition: all 300ms ease-in-out;
}

.refresh-button:hover{
  background: linear-gradient(135deg, rgb(73, 66, 36) 0%, rgb(168, 144, 56) 100%);
  box-shadow: 0 0 7px 0 rgba(255, 235, 183, 0.247);
  margin: 2rem 0 1.5rem;
  transition: all 300ms ease-in-out;
}

.redeem-stats {
  background-color: var(--transparent-black);
  color: var(--main-white);
  border: 1px solid var(--faded-gold);
  box-shadow: inset 0 0 5rem 0 var(--transparent-faded-gold);
}

.redeemed-fees-button {
  background: var(--lighter-gold);
  color: var(--main-black);
  font-weight: 700;
}

.no-redeemed-fees-button {
  background: var(--faded-white);
  color: var(--main-black);
  font-weight: 500;
}

.bg-redemption-history{
 background: var(--main-black);
}

/* bond page */

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

table th {
  padding: 10px;
  background-color: var(--bond-table-header-bg-color);
  color: var(--main-white);
  border-top: 1px solid var(--bond-table-header-border-color);
  border-bottom: 1px solid var(--bond-table-header-border-color);
}

/* Adding rounded corners */
table th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid var(--bond-table-header-border-color);
}

table th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid var(--bond-table-header-border-color);
}

/* Using a pseudo-element to create inner borders */
table th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #333; /* same as border color */
}

.dropdown-container {
  position: relative;
  z-index: 10; /* Ensure the dropdown is above other elements */
}

.dropdown-menu {
  position: absolute;
  z-index: 20; /* Ensure the dropdown menu is above other elements */
}

table td{
  border-bottom: 1px solid var(--bond-table-header-border-color);
}

.bond-claim{
  height: fit-content;
}

.bond-buy{
  background-color: var(--bond-buy-bg-color);
  margin-top: 1rem;
}

.bond-buy-text-container{
  background-color: var(--bond-buy-text-container-color);
}

.back-button{
  background-color: var(--transparent-black);
  border: 1px solid var(--faded-gold);
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 0px var(--faded-gold-lighter);
  padding: 0.5rem 1rem;
  transition: all 0.3s linear;
}

.back-button:hover{
  background-color: var(--bond-buy-bg-color);
  border: 1px solid var(--faded-gold);
  box-shadow: inset 200px 0px 0px 0px var(--faded-gold-lighter);
}

.create-bond-button{
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
}

.create-bond-button:hover{
  color: var(--text-gold-whiter);
}

.bond-create-text-container{
  background-color: var(--bond-create-text-container-color);
}

.bond-creation-div, .claim-div, .nft-metadata-div{
  background-color: var(--bond-buy-bg-color);
  border: 1px solid var(--sidebar-border-color);
}

/*end of bond page*/

@media screen and (max-width: 800px) {

  .sidebar {
    min-width: 35vw;
  }

}

@media screen and (max-width: 450px) {

  .sidebar {
    min-width: 50vw;
  }

}


@media screen and (min-width: 1300px) {


  /*analytic dashboard page desktop*/

  .page-wrapper{
    padding: 0 15%;
    margin-top: 4rem;
  }

  .pie-chart-container{
    height: 450px;
  }

  .loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }
  
  @-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* redeem analytics desktop */

  .redemption-data-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5rem;
  }

  .redeem-data-width {
    flex: 1 1 0;
    min-width: fit-content;
    box-sizing: border-box;
  }

  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .bond-creation-div, .claim-div, .nft-metadata-div, .bond-buy{
    width: 60%;
    margin: 0 auto;
  }

  .bond-buy{
    margin-top: 1rem;
  }

  .flex-center{
    justify-content: center;
  }

}

/* Add these styles to your App.css */
.modal-content {
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
}

/* Add custom scrollbar styling */
.no-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.no-scrollbar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background: #ffd700;
  border-radius: 3px;
}

